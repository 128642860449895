class CookiesConsent {
  constructor(options) {

    var defaults = {
      modal: '.cookies-notice',
      button: '.cookie-button'
    };

    this.params = Object.assign({}, defaults, options || {});
    this.start();

  }

  start() {
    this.status = this.getStatus();
    if (!this.status) {
      this.modal = document.querySelector(this.params.modal);
      this.button = document.querySelector(this.params.button);
      this.button.addEventListener('click', (event) => this.onButtonClick(event));
      this.showModal();
    }
  }

  showModal() {
    this.modal.style.display = 'block';
  }

  hideModal() {
    this.modal.style.display = 'none';
  }

  onButtonClick(event) {
    event.preventDefault();
    this.setStatus(true);
    this.hideModal();
  }

  getStatus() {
    return window.localStorage.getItem('cookies_consent');
  }

  setStatus(value) {
    window.localStorage.setItem('cookies_consent', value);
  }
}

export {
  CookiesConsent as
  default
};
