import Carousel from './modules/carousel';
import Gallery from './modules/gallery';
import Global from './modules/global';
import Cmp from './modules/cmp';
import CookiesConsent from './modules/cookiesconsent';

const modules = {
  Carousel,
  Gallery,
  Global,
  Cmp,
  CookiesConsent
};

var elements = document.querySelectorAll('.joomla-script-options');
if (elements.length > 0) {
  var options = JSON.parse(elements[0].text || elements[0].textContent);
  options.modules.forEach((module) => {
    if (modules[module.name]) {
      new modules[module.name](module.options);
    }
  });
}
