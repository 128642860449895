class Cmp {
  constructor(options) {
    let defaults = {};
    this.params = Object.assign({}, defaults, options || {});
    this.start();
  }

  start() {

    // If CMP is not loaded check again after 1 second
    if (typeof __tcfapi === 'undefined') {
      window.setTimeout(() => {
        this.start();
      }, 1000);
      return;
    }

    // Check consent
    __tcfapi('addEventListener', 2, function(tcData, success) {


      if (!success) {
        return;
      }

      if (tcData.eventStatus === 'useractioncomplete' || tcData.eventStatus === 'tcloaded') {
	      
	    /*
		    1: Store and/or access information on a device
		    2: Select basic ads
		    3: Create a personalised ads profile
		    4: Select personalised ads
		    5: Create a personalised content profile
		    6: Select personalised content
		    7: Measure ad performance
		    8: Measure content performance
		    9: Apply market research to generate audience insights
	    */

        // Store and/or access information on a device (Purpose 1)
        if (!tcData.purpose.consents['1']) {
          return;
        }

        // @TODO Handle vendors based on cmp status
        
      }


    });
  }
}

export {
  Cmp as
  default
};
