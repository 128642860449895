class LinksTracker {

  constructor(options) {
    var defaults = {
      containerSelector: '.links-tracker-container'
    };
    this.params = Object.assign({}, defaults, options || {});
    this.containers = document.querySelectorAll(this.params.containerSelector);
    this.addEvents();
    this.track();
  }

  addEvents() {
    for (var i = 0; i < this.containers.length; i++) {
      var container = this.containers[i];
      var area = container.dataset.area;
      if (area) {
        this.addTrackEvent(container, area);
      }
    }
  }

  addTrackEvent(container, area) {
    container.addEventListener('click', (event) => {
      if (typeof event.composedPath === 'function') {
        var path = event.composedPath();
        for (var i = 0; i < path.length; i++) {
          var el = path[i];
          if (el.tagName === 'A') {
            if (el.href && el.host === window.location.host) {
              var date = new Date();
              var time = date.getTime();
              window.sessionStorage.setItem('trackLinkArea', area);
              window.sessionStorage.setItem('trackLinkTime', time);
              window.sessionStorage.setItem('trackLinkHref', el.href);
            }
            break;
          }
        }
      }
    });
  }

  track() {
    var area = window.sessionStorage.getItem('trackLinkArea');
    var href = window.sessionStorage.getItem('trackLinkHref');
    if (area && href && window.ga) {
      var link = document.createElement('a');
      link.href = href;
      if (link.host === window.location.host) {
        var time = window.sessionStorage.getItem('trackLinkTime');
        if (time) {
          var date = new Date();
          var now = date.getTime();
          if (now - time < 60 * 1000) {
            window.ga('send', 'event', 'Internal Link', area, link.pathname);
          }
        }
      }
    }
    window.sessionStorage.removeItem('trackLinkTime');
    window.sessionStorage.removeItem('trackLinkArea');
    window.sessionStorage.removeItem('trackLinkHref');
  }
}

export {
  LinksTracker as
  default
};
