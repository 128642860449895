import Tabs from './tabs.js';
import LinksTracker from './linkstracker';

class Global {
  constructor(options) {
    var defaults = {};
    this.params = Object.assign({}, defaults, options || {});
    this.run();
  }

  run() {
    this.menu();
    this.tabs();
    this.detectPassiveListenersSupport();
    this.scrollEvents();
    new LinksTracker();
  }

  menu() {
    var burgers = document.querySelectorAll('.rich-menu');
    burgers.forEach((burger) => {
      burger.addEventListener('click', function(event) {
        event.preventDefault();
        this.classList.toggle('is-open');
        document.body.classList.toggle('menu-opened');
      });
    });
  }

  tabs() {
    var i, tabs = document.querySelectorAll('.tabs');
    for (i = 0; i < tabs.length; i++) {
      new Tabs({
        container: tabs[i]
      });
    }
  }

  detectPassiveListenersSupport() {
    try {
      const options = {
        get passive() {
          this.passiveSupported = true;
          return false;
        }
      };

      window.addEventListener("test", null, options);
      window.removeEventListener("test", null, options);
    } catch (err) {
      this.passiveSupported = false;
    }
  }
  
  scrollEvents() {
    console.log('ddd');
    window.addEventListener('scroll', () => {

      let topScroll = window.scrollY;

      if (topScroll > 30) {
        document.body.classList.add('sticky-header');
      } else {
        document.body.classList.remove('sticky-header');
      }

    }, this.passiveSupported ? {
      passive: true
    } : false);
  }

}

export {
  Global as
  default
};
